/*eslint-disable*/
// @ts-nocheck
import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes, ThemeOptions } from '@material-ui/core/styles';
import type { Direction, Theme } from '@material-ui/core';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';
import colors from './colors';
import properties from './properties';
import { Palette } from '@material-ui/icons';
declare module '@mui/material/styles' {
  interface CustomPalette {
    appColors: {
      socialButton: {
        main: string;
        hover: string;
        border: string;
        text: string;
      },
      input: {
        placeHolder: string;
      },
      link: string
    };
  }
  interface CustomPaletteOptions {
    appColors: {
      socialButton: {
        main: React.CSSProperties['color'];
        hover: React.CSSProperties['color'];
        border: React.CSSProperties['color'];
        text: React.CSSProperties['color'];
      },
      input: {
        placeHolder: React.CSSProperties['color'];
      },
      link: React.CSSProperties['color']
    };
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPaletteOptions { }
}
interface ThemeConfig {
  language?: 'en' | 'ar';
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const baseOptions: ThemeOptions = {
  language: 'en',
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: properties.borderRadius,
          boxShadow: 'none',
          backgroundColor: colors.mainColor,
          color: colors.white,
          height: properties.height,
          fontSize: '12px',
          '&:hover': {
            backgroundColor: colors.buttonHover,
            color: colors.white,
            boxShadow: 'none'
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.mainColor,
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: colors.littleDarkGrey
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily:
      '"FS Albert Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 100,
      fontSize: '3.5rem',
    },
    h2: {
      fontWeight: 200,
      fontSize: '3rem',
    },
    h3: {
      fontWeight: 300,
      fontSize: '2.25rem',
    },
    h4: {
      fontWeight: 400,
      fontSize: '2rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
};

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '10.5px 14px',
          },
        },
      },
    },
    palette: {
      common: {
        white: colors.white,
        black: colors.black,
      },
      action: {
        active: '#6b778c',
        hover: colors.mainColor,
      },
      background: {
        default: colors.bgBlackShade,
        paper: colors.white
      },
      appColors: {
        socialButton: {
          main: colors.white,
          hover: colors.lightGrey,
          border: colors.littleDarkGrey,
          text: colors.bodyText
        },
        link: colors.darkGray2
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: colors.mainColor,
        dark: colors.mainColorDark,
        light: colors.mainColorLight,
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#222',
        secondary: '#6b778c',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: colors.darkGrey,
        mainChat: colors.lightGreyFiftyPercent,
        paper: colors.darkGrey
      },
      appColors: {
        socialButton: {
          main: colors.darkGrey,
          hover: colors.darkBlue,
          border: colors.littleDarkGrey,
          text: colors.white
        },
        link: colors.white
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shadows: darkShadows,
  },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.DARK];
  }

  let theme = createTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 2.5,
          },
        }),
      },
      {
        direction: config.direction,
      },
      {
        language: config.language,
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
