/*eslint-disable*/
import React, { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
import { Lightbox } from 'react-modal-image';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Link,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import { useSelector } from 'src/store';
import { useDispatch } from 'react-redux';
import { markMessageAsSeen } from 'src/slices/chat';
import useSettings from 'src/hooks/useSettings';
// import { OPENAI_ID } from '../SelectModel';
import OpenAIIcon from 'src/icons/OpenAIIcon.svg';
import { ChatMessageWidth } from 'src/constants';
import ChatBotIcon from 'src/icons/chat-bot.svg';
import ChatLikeIcon from 'src/icons/chat-like-btn.svg';
import ChatDislikeIcon from 'src/icons/chat-dislike-btn.svg';
import ChatToolButton from './ChatToolButton';
import ReactMarkdown from 'react-markdown';
import { CopyIconButton } from 'src/components/CopyIconButton/index';
import TypingMarkdown from './TypingMarkdown';
import { ExpandMore, InfoOutlined, Refresh, SourceOutlined } from '@material-ui/icons';
import DocumentText from 'src/icons/DocumentText';
import colors from 'src/theme/colors';
import { extractFileName } from 'src/utils';
import { DEPARTMENTS, SECURITY_CLEARANCE } from 'src/components/navigation/MainNav';

interface ChatMessageProps {
  body: any;
  loader: any;
  contentType?: string;
  createdAt?: number;
  senderAvatar?: string;
  senderName?: string;
  senderType?: 'rta' | 'contact' | 'user';
  threadKey?: any;
  thread?: any;
  index?: any;
  showIcons: boolean;
  msgId?: string;
  msgLoading: boolean;
  source_documents?: any;
}

const ChatMessage: FC<ChatMessageProps> = (props) => {
  const {
    loader,
    body,
    contentType,
    senderAvatar,
    senderType,
    threadKey,
    thread,
    index,
    showIcons,
    msgId,
    msgLoading,
    source_documents,
    ...other
  } = props;
  const dispatch = useDispatch();
  const [expandMedia, setExpandMedia] = useState<boolean>(false);
  const { activeThreadId } = useSelector((state) => state.chat);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const { selectedModel } = thread;
  const { byId = {} } = useSelector((state) => state.metadata);
  // const [animationCompleted, setAnimationCompleted] = useState<boolean>(false);
  const { settings } = useSettings();

  const selectAvatarSrc = () => {
    // let avatarIcon;
    // if (body.icon === 'DEFAULT' || body === 'showLoader') {
    //   if (selectedModel == OPENAI_ID) {
    //     avatarIcon = OpenAIIcon; // OPENAI
    //   } else {
    //     avatarIcon = ChatBotIcon;
    //   }
    // } else {
    //   avatarIcon = body.icon;
    // }
    return ChatBotIcon;
  };

  function moment(createdAt: number) {
    throw new Error('Function not implemented.');
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background:
            senderType === 'user'
              ? smDown
                ? 'white'
                : 'background.default'
              : '#F3F3F3',
          py: 2,
        }}
        {...other}
      >
        <Box
          sx={{
            display: 'flex',
            maxWidth: ChatMessageWidth,
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: !smDown ? 'row' : 'column',
            px: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: !smDown ? 'center' : 'flex-start',
              width: !smDown ? '87%' : '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              {senderType === 'user' ? (
                <Avatar
                  variant="square"
                  className="user-avatar"
                  src={senderAvatar}
                  sx={{
                    height: 30,
                    width: 30,
                    borderRadius: '5px',
                  }}
                />
              ) : (
                <Avatar
                  variant="square"
                  sx={{
                    height: 30,
                    width: 30,
                    borderRadius: '5px',
                    backgroundColor: 'transparent',
                  }}
                >
                  <img
                    src={selectAvatarSrc()}
                    style={{ height: '30px', width: '30px' }}
                  />
                </Avatar>
              )}
            </Box>
            <Box
              sx={{
                marginLeft: '4px',
                marginTop: !smDown ? '0px' : '4px',
              }}
            >
              <Box
                sx={{
                  color:
                    settings.theme == 'DARK'
                      ? senderType === 'user'
                        ? '#3C4A59'
                        : 'white'
                      : '#3C4A59',
                  px: 1,
                }}
              >
                <Box sx={{ mt: 0, ml: 1 }}>
                  {contentType === 'image' ? (
                    <Box
                      onClick={(): void => setExpandMedia(true)}
                      sx={{
                        mt: 2,
                        '& img': {
                          cursor: 'pointer',
                          height: 'auto',
                          maxWidth: '100%',
                        },
                      }}
                    >
                      <img alt="Attachment" src={body} />
                    </Box>
                  ) : (
                    <Typography
                      color="inherit"
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: '0.875rem',
                          sm: '0.875rem',
                          md: '0.875rem',
                          lg: '1rem',
                          xl: '1.125rem'
                        },
                        color: '#3C3C3C',
                      }}
                    >
                      {senderType == 'rta' &&
                        body !== 'showLoader' &&
                        !showIcons ? (
                        <TypingMarkdown
                          content={body}
                          speed={20}
                          threadId={thread.external_chat_id}
                          showTyping={true}
                          msgId={msgId}
                          onTypingComplete={async () => {
                            await dispatch(
                              markMessageAsSeen(thread.external_chat_id, msgId, true)
                            );
                          }}
                        />
                      ) : senderType === 'rta' && body === 'showLoader' ? (
                        loader
                      ) : (
                        <TypingMarkdown
                          content={body}
                          speed={20}
                          showTyping={false}
                          msgId={msgId}
                          threadId={''}
                          onTypingComplete={() => { }}
                        />
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {senderType == 'rta' &&
            showIcons && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: !smDown ? 'space-between' : 'flex-end',
                  height: '100%',
                  alignItems: 'center',
                  ml: 0,
                }}
              >

                {/* <>
                <ChatToolButton
                  src={<CopyIconButton value={body} />}
                  onClick={() => { }}
                />
                {false && (
                  <ChatToolButton
                    sx={{ p: 0, mx: 1 }}
                    src={ChatLikeIcon}
                    onClick={() => { }}
                  />
                )}
                {false && (
                  <ChatToolButton src={ChatDislikeIcon} onClick={() => { }} />
                )}
              </> */}
              </Box>
            )}
          {senderType == 'rta' &&
            showIcons && source_documents?.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: !smDown ? 'space-between' : 'flex-end',
                  height: '100%',
                  alignItems: 'center',
                  ml: 0,
                }}
              >
                <ChatToolButton
                  sx={{ p: 0, mx: 0.5 }}
                  src={<CopyIconButton value={body} />}
                  onClick={() => { }}
                />

                <ChatToolButton
                  sx={{ p: 0, mx: 0.5, color: '#6b778c' }}
                  src={ChatLikeIcon}
                  onClick={() => { }}
                />
                <ChatToolButton sx={{ p: 0, mx: 0.5 }} src={ChatDislikeIcon} onClick={() => { }} />
                <ChatToolButton sx={{ p: 0, mx: 0.5 }} src={<Refresh
                  sx={{
                    color: 'lightgray',
                    fill: 'lightgray',
                    transform: 'scaleX(-1)',
                    '&:hover': {
                      color: 'darkgray',
                      fill: 'darkgray',
                    }
                  }}
                />} onClick={() => { }} />
                <ChatToolButton
                  src={<InfoOutlined sx={{
                    color: 'lightgray',
                    fill: 'lightgray',
                    '&:hover': {
                      color: 'darkgray',
                      fill: 'darkgray',
                    }
                  }} />}
                  sx={{ p: 0, mx: 0.5 }}
                  onClick={() => setShowDetails(!showDetails)}
                />
                {/* <ChatToolButton src={ChatDislikeIcon} onClick={() => { }} /> */}
                {/* <InfoOutlined onClick={ } sx={{ color: '#888', cursor: 'pointer' }} /> */}

                {/* <>
                <ChatToolButton
                  src={<CopyIconButton value={body} />}
                  onClick={() => { }}
                />
                {false && (
                  <ChatToolButton
                    sx={{ p: 0, mx: 1 }}
                    src={ChatLikeIcon}
                    onClick={() => { }}
                  />
                )}
                {false && (
                  <ChatToolButton src={ChatDislikeIcon} onClick={() => { }} />
                )}
              </> */}
              </Box>
            )}
        </Box>
      </Box>
      {
        senderType == 'rta' &&
        showIcons && (
          <Box className={`${!showDetails || !source_documents?.length ? 'hide' : ''}`}
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Accordion elevation={0} disableGutters sx={{
              borderTop: theme => `1px solid ${theme.palette.divider}`,
              borderBottom: theme => `1px solid ${theme.palette.divider}`,
              maxWidth: ChatMessageWidth,
              width: '100%',
              px: 2,
            }}
            >
              <AccordionSummary sx={{ margin: 0, height: '2rem', minHeight: '2rem' }}>
                <Typography sx={{ fontSize: '.875rem', display: 'flex', alignItems: 'center' }}>Documents
                  <Chip sx={{
                    marginLeft: '0.25rem',
                    height: '1rem',
                    borderRadius: '0.25rem',
                    fontSize: '0.75rem',
                    'span': {
                      padding: '0.25rem',
                    }
                  }} variant='outlined' label="source_documents" />
                  <Chip sx={{
                    marginLeft: '0.25rem',
                    height: '1rem',
                    borderRadius: '0.25rem',
                    fontSize: '0.75rem',
                    'span': {
                      padding: '0.25rem',
                    }
                  }} variant='filled' label={source_documents?.length} /></Typography>
              </AccordionSummary>
              <AccordionDetails>
                {source_documents && source_documents.map(sd => (
                  <Accordion disableGutters sx={{ border: theme => `1px solid ${theme.palette.divider}` }}>
                    <AccordionSummary sx={{
                      margin: 0,
                      height: '2rem',
                      minHeight: '2rem',
                      '.MuiAccordionSummary-content': {
                        display: 'flex', alignItems: 'center',
                      }
                    }} expandIcon={<ExpandMore />}>
                      <DocumentText sx={{ width: '1rem' }} />
                      <Typography sx={{ marginLeft: '0.5rem', fontSize: '.875rem', }}>{extractFileName(sd[0].metadata.url) ?? 'Document'}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ width: '55vw', maxWidth: '800px', fontSize: theme => theme.typography.pxToRem(14) }}>
                        {sd[0].page_content}
                      </Typography>
                      <Box sx={{
                        marginTop: theme => theme.typography.pxToRem(8),
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: theme => theme.typography.pxToRem(8),
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                      }}>
                        {/* <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>page: </b>
                          {'  '}
                          {'5'}
                        </Typography> */}

                        <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>source:</b>
                          {'  '}
                          <a style={{
                            pointerEvents: 'none',
                          }} href={sd[0].metadata.url} onClick={(ev: any) => ev.preventDefault()} aria-disabled target='_blank'>{extractFileName(sd[0].metadata.url) ?? ''}</a>
                        </Typography>

                        <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>department: </b>
                          {byId[sd[0].metadata.department_id]}
                        </Typography>
                        <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>security level: </b>
                          {SECURITY_CLEARANCE[sd[0].metadata.security_level.toLowerCase()]}
                        </Typography>
                        <Typography sx={{
                          background: colors.lightGreyFiftyPercent,
                          padding: '8px 5px',
                          borderRadius: '2px',
                          fontSize: theme => theme.typography.pxToRem(14),
                          display: 'inline'
                        }}>
                          <b>score: </b>
                          {(parseFloat(sd[1]) * 100).toFixed(2)}
                        </Typography>
                      </Box>
                    </AccordionDetails >
                  </Accordion >
                ))}
              </AccordionDetails >
            </Accordion >
          </Box >
        )}
    </>
  );
};

ChatMessage.propTypes = {
  loader: PropTypes.any,
  body: PropTypes.any.isRequired,
  senderAvatar: PropTypes.string.isRequired,
  senderType: PropTypes.oneOf(['rta', 'user']),
  threadKey: PropTypes.any,
  thread: PropTypes.any,
  index: PropTypes.any,
  showIcons: PropTypes.bool,
  msgId: PropTypes.string,
};

export default React.memo(ChatMessage);
