/* eslint-disable */
// @ts-nocheck
import { MessagePayload } from 'src/types/chat';
import API from './apiInstance';

export const addMessage = (payload: MessagePayload) => API.post('/chat', payload);

export const getAPIHistory = () => API.get('/chat/list');
// Not bBeing Used Currently. Commented Out For Future Purpose
// export const getThreadsList = () => API.get('/chat/list');

// export const getConversationById = (threadId: string) => API.get(`/chat/${threadId}`);

// export const deleteThreadById = (threadId: string) => API.delete(`/chat/${threadId}`);
