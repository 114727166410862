/*eslint-disable*/
import { useCallback, useState, useEffect } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  TableContainer,
  CircularProgress,
  Dialog,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from '@material-ui/core';
import PencilAltIcon from '../../../../icons/PencilAlt';
import SearchIcon from '../../../../icons/Search';
import getInitials from '../../../../utils/getInitials';
import Scrollbar from '../../../../components/Scrollbar';
import { debounce, isEqual } from 'lodash';
import Label from 'src/components/Label';
import PreviewIcon from '@material-ui/icons/Preview';
import ChatIcon from '@material-ui/icons/Chat';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import PencilIcon from 'src/icons/Edit.svg';
import { ChatMessage } from 'src/components/dashboard/chat';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import toast from 'react-hot-toast';
import {getAPIHistory} from 'src/api/chat';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';

export const TableCellStyle = {
  padding: '8px 16px',
  fontSize: '0.75rem'
};

export const TableHeaderCellStyle = {
  padding: '8px 16px',
  fontWeight: 'bold',
};



interface APICallHistoryProps {
  apiCallHistory: any;
  loading: boolean;
  getAPICallHistory: any;
  tableParams: { page: number; limit: number; query: string };
  setTableParams: React.Dispatch<
    React.SetStateAction<{ page: number; limit: number; query: string }>
  >;
}

const dateFormatOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };

const APICallHistoryTable: FC<APICallHistoryProps> = (props) => {
  const { apiCallHistory, loading, getAPICallHistory, tableParams, setTableParams, ...other } =
    props;

  //const [apiCallHistory1,setAPICallHistory] = useState<any>([]);
  const [filteredapiCallHistory1,setFilteredAPICallHistory] = useState<any>([]);
  // const apiCallHistory1 = [
  //   {
  //     "request_timestamp": "2024-01-11T10:08:15.590968",
  //     "request_id": "f21f5db3-1613-4ae7-a682-c70b603352d4",
  //     "intent": [
  //       "wrong item"
  //     ],
  //     "summary": "Müşteri, ananas dilimli geleceği söylenmiş ancak kabuklu gelmiştir ve bu durumdan dolayı memnuniyeti düşmüştür.",
  //     "guideline_to_agent": [
  //       "Müşterinin siparişini kontrol ettim, ananas dilimli kefir ürünü sipariş verilmiş.",
  //       "Ancak müşteriye kabuklu kefir gönderilmiş olduğu için memnuniyetsiz.",
  //       "Kontrollü süreçteyiz, bu nedenle müşterinin iade veya indirim talebini ilk kontakta karşılamamalıyım.",
  //       "Backoffice ekibine bildirim açmalı ve onlarla iletişime geçip, müşteriye doğru ürünün tekrar gönderilmesini sağlamalıyım."
  //     ],
  //     "message_to_customer": [
  //       "Değerli müşterimiz, siparişinizi kontrol ettim. Ananas dilimli kefir ürününü satın almışsınız ancak size kabuklu kefir gönderilmiş.",
  //       "Ürün hatasından dolayı çok özür dileriz. Kontrollü süreçte olduğumuz için maalesef ilk kontakta size destek veremiyorum.",
  //       "Ancak hemen backoffice ekibimize durumu bildireceğim ve sizin için en kısa sürede doğru ürünün tekrar gönderilmesini sağlayacağım.",
  //       "Lütfen biraz sabırla bekleyin, en kısa sürede sizinle iletişime geçeceğiz. Anlayışınız için teşekkür ederiz."
  //     ],
  //     "payload": {
  //       "intent": [
  //         "wrong item"
  //       ],
  //       "process": "kontrol odaklı süreç",
  //       "summary": "Müşteri, ananas dilimli geleceği söylenmiş ancak kabuklu gelmiştir ve bu durumdan dolayı memnuniyeti düşmüştür.",
  //       "vertical": "market",
  //       "customer_order_info": {
  //         "products": [
  //           {
  //             "price": 33.9,
  //             "product_name": "Altınkılıç Sade Kefir (250 ml)",
  //             "category_name": "Milk & Dairy",
  //             "subcategory_name": "Ayran & Kefir"
  //           }
  //         ],
  //         "is_bundle": true,
  //         "order_status": "ON_ADDRESS",
  //         "checkout_date": "2024-01-02T18:52:32.250000+00:00",
  //         "minutes_since_checkout": 20
  //       }
  //     }
  //   },
  //   {
  //     "request_timestamp": "2024-01-11T10:12:26.955594",
  //     "request_id": "21827856-4b48-4ed2-9627-47de8252e307",
  //     "intent": [
  //       "wrong item"
  //     ],
  //     "summary": "Müşteri, ananas dilimli geleceği söylenmiş ancak kabuklu gelmiştir ve bu durumdan dolayı memnuniyeti düşmüştür.",
  //     "guideline_to_agent": [
  //       "Müşterinin siparişini kontrol ettim, ananas dilimli kefir ürünü sipariş verilmiş.",
  //       "Ancak müşteriye kabuklu kefir gönderilmiş olduğu için memnuniyetsiz.",
  //       "Kontrollü süreçteyiz, bu nedenle müşterinin iade veya indirim talebini ilk kontakta karşılamamalıyım.",
  //       "Backoffice ekibine bildirim açıp, onların yönlendirmesiyle iade veya indirim işlemini gerçekleştirmeliyim."
  //     ],
  //     "message_to_customer": [
  //       "Değerli müşterimiz, siparişinizi kontrol ettik. Ananas dilimli kefir ürününü satın almışsınız.",
  //       "Ancak size kabuklu kefir gönderilmiş olduğunu görüyoruz. Bu hatadan dolayı özür dileriz.",
  //       "Kontrollü süreçte olduğumuz için ilk kontakta iade veya indirim yapamıyoruz.",
  //       "Ancak hemen backoffice ekibimize bildirimde bulunup, bu konuda sizin için en uygun çözümü sunmalarını sağlayacağız."
  //     ],
  //     "payload": {
  //       "intent": [
  //         "wrong item"
  //       ],
  //       "process": "kontrol odaklı süreç",
  //       "summary": "Müşteri, ananas dilimli geleceği söylenmiş ancak kabuklu gelmiştir ve bu durumdan dolayı memnuniyeti düşmüştür.",
  //       "vertical": "market",
  //       "customer_order_info": {
  //         "products": [
  //           {
  //             "price": 33.9,
  //             "product_name": "Altınkılıç Sade Kefir (250 ml)",
  //             "category_name": "Milk & Dairy",
  //             "subcategory_name": "Ayran & Kefir"
  //           }
  //         ],
  //         "is_bundle": true,
  //         "order_status": "ON_ADDRESS",
  //         "checkout_date": "2024-01-02T18:52:32.250000+00:00",
  //         "minutes_since_checkout": 20
  //       }
  //     }
  //   }
  // ];
  const { page, limit, query } = tableParams;

  const [openJsonDialog, setOpenJsonDialog] = useState<boolean>(false);
  const [selectedJson, setSelectedJson] = useState<any>(null);
  const [selectedRequestId, setSelectedRequestId] = useState<string>('');

  const debouncedgetAPICallHistory = useCallback(debounce(getAPICallHistory, 500), []); // Adjust the debounce delay as needed (e.g., 500 milliseconds)

  const getAPIHistory =async () => {
      // const resp = await getAPIHistory();
      // console.log(resp);
      // setAPICallHistory(resp);
  }
  
  useEffect(() => {
    //getAPIHistory();
    
    setFilteredAPICallHistory(apiCallHistory);
  }, []);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {

    const newValue = event.target.value;


    setTableParams((prevParams) => ({
      ...prevParams,
      page: 0,
      query: newValue,
    }));

    // if (newValue) {
    //   const newFilteredData = apiCallHistory.filter(item => 
    //     (item.intent.join(',').indexOf(newValue) > -1 || item.request_id.indexOf(newValue) > -1)
    //   );

    //   setFilteredAPICallHistory(newFilteredData);
    // }
    // else{
    //   setFilteredAPICallHistory(apiCallHistory);
    // }

    // setTableParams((prevParams) => ({ ...prevParams, page: 0, query: newValue }));
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    getAPICallHistory(newPage, limit, query);
    setTableParams((prevParams) => ({ ...prevParams, page: newPage }));
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const updatedLimit = parseInt(event.target.value, 10);
    getAPICallHistory(page, updatedLimit, query);
    setTableParams((prevParams) => ({ ...prevParams, limit: updatedLimit }));
  };


  const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 90 , hide: true},
    {
      field: 'request_timestamp',
      headerName: 'Request Timestam',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'TableHeaderCellStyle',
      renderCell: (params) => (
        <span>{new Date(params.value).toLocaleString()}</span>
      ),

    },
    {
      field: 'request_id',
      headerName: 'Request ID',
      width: 290,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'TableHeaderCellStyle',
      cellClassName: 'custom-cursor-cell',
    },
    {
      field: 'intent',
      headerName: 'Intent',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
      headerClassName: 'TableHeaderCellStyle'
    },
    {
      field: 'summary',
      headerName: 'Summary',
      sortable: false,
      disableColumnMenu: true,
      width: 400,
      headerClassName: 'TableHeaderCellStyle',
      renderCell: (params) => (
        <Tooltip title={params.value}  arrow>
          {params.value}
        </Tooltip>
      )
           
    },
    {
      field: 'guideline_to_agent',
      headerName: 'Guideline to Agent',
      sortable: false,
      disableColumnMenu: true,
      width: 400,
      headerClassName: 'TableHeaderCellStyle',
      renderCell: (params) => (
        <Tooltip title={params.value}  arrow sx={{ backgroundColor: '#4c3398', color: 'white',fontSize: 12 }}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'message_to_customer',
      headerName: 'Message to Customer',
      sortable: false,
      disableColumnMenu: true,
      width: 400,
      headerClassName: 'TableHeaderCellStyle',
      renderCell: (params) => (
        <Tooltip title={params.value}  arrow sx={{ backgroundColor: '#4c3398', color: 'white',fontSize: 12 }}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
  ];





  const openQuestionModal = (params) => {
    // params will contain information about the clicked cell
    if(params.field =="request_id"){
        setSelectedRequestId(params.value);
        setSelectedJson(params.row.payload);
        setOpenJsonDialog(true);
    }

    // You can perform actions based on the clicked cell here
  };


  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 300,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Search Request ID or Intent"
            value={query}
            variant="outlined"
          />
        </Box>
      </Box>

      <Box sx={{ p: 2 }} className="pd-box-top0">
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={apiCallHistory}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onCellClick={openQuestionModal}
            disableRowSelectionOnClick
            //autoPageSize
            sx={{
              '& .TableHeaderCellStyle': {
                backgroundColor: '#4c3398', // Set your desired background color
                color: 'white', // Set your desired text color
                padding: '8px 16px',
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: '#c0abff', // Set your desired hover background color
              },
              '& .bordered-cell': {
                border: '1px solid #e0e0e0', // Set your desired border color and style
              },
              '& .custom-cursor-cell': {
                cursor: 'pointer', // Set your desired cursor style
              },
            }}
          />
      </Box>
        
        {/* <TableContainer>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ minWidth: 700 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={TableHeaderCellStyle}>Request Timestamp</TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Request ID
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Intent
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Summary
                        </TableCell>
                        <TableCell sx={TableHeaderCellStyle}>
                          Guideline to Agent
                        </TableCell>

                        <TableCell sx={TableHeaderCellStyle} align="center">
                          Message to Customer
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {apiCallHistory.map((apiHistory) => (
                        <TableRow hover key={apiHistory.request_id}>
                          <TableCell sx={TableCellStyle}>
                            {apiHistory.request_timestamp}
                          </TableCell>
                          <TableCell sx={{...TableCellStyle, cursor: 'pointer'}} onClick={()=>{
                            setSelectedRequestId(apiHistory.request_id);
                            setSelectedJson(apiHistory.payload);
                            setOpenJsonDialog(true);
                          }}>
                            {apiHistory.request_id}
                          </TableCell>

                          <TableCell sx={TableCellStyle}>
                            {apiHistory.intent.join(',\n')}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {apiHistory.summary}
                          </TableCell>
                          <TableCell sx={TableCellStyle}>
                            {apiHistory.guideline_to_agent.join(",\n")}
                          </TableCell>

                          <TableCell sx={TableCellStyle}>
                            {apiHistory.message_to_customer.join(",\n")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
            </>
          )}
        </TableContainer>


        <TablePagination
          component="div"
          count={apiCallHistory?.count || 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={false}
        /> */}
      </Box>
      <Dialog
        open={openJsonDialog}
        BackdropProps={{ style: { opacity: 0.5 } }}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle sx={{ pb: 0 }}>
          <span>
            <Typography style={{ display: 'inline-block' }} component="h1">
              REQUEST ID:
            </Typography>
            <Typography
              style={{ display: 'inline-block', fontWeight: 'bold' }}
              component="h1"
            >
              &nbsp;{`${selectedRequestId}`}
            </Typography>
          </span>
          <Divider sx={{ width: '100%', borderWidth: '1px', mt: '10px' }} />
        </DialogTitle>
        <DialogContent sx={{ pt: 0 }}>
          <Box width="100%" sx={{ display: 'flex', height: '58vh' }}>
            <Box
              width="100%"
              sx={{
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <List
                sx={{
                  width: '100%',
                  background: '#FDFDFD',
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                <div><pre>{selectedJson ? JSON.stringify(selectedJson, null, 2) : "" }</pre></div>
              </List>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ pr: 3 }}>
          <Button
            className="btn-document btnPrimary"
            onClick={() => {
              setSelectedJson(null);
              setOpenJsonDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card >
  );
};

APICallHistoryTable.propTypes = {
  apiCallHistory: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getAPICallHistory: PropTypes.func.isRequired,
  tableParams: PropTypes.any.isRequired,
  setTableParams: PropTypes.func.isRequired,
};

export default APICallHistoryTable;
