/*eslint-disable*/
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { addNewThread } from 'src/slices/chat';
import { useDispatch } from 'react-redux';
import { useSelector } from 'src/store';
import colors from 'src/theme/colors';

const useStyles = makeStyles({
  root: {
    color: colors.chatSampleCardText,
    transition: 'background-color 0.3s ease', // Add transition for smooth color change effect
    display: 'inline-flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.mainColor, // Change the background color on hover
      color: colors.white,
      cursor: 'pointer', // Set the cursor to hand (pointer) on hover
    },
  },
  bullet: {
    display: 'inline-block',
    margin: '5px',
    transform: 'scale(0.8)',
    padding: '5px',
  },
  title: {
    fontSize: '1.125rem',
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SimpleCard({ text }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedModel } = useSelector((state) => state.chat);

  return (
    <Card
      className={classes.root}
      sx={{
        m: 1,
        backgroundColor: colors.chatSampleCardBg,
        width: {
          xs: 270,
          sm: 270,
          md: 270,
          lg: 270,
          xl: 344
        },
        height: {
          xs: 123,
          sm: 123,
          md: 123,
          lg: 123,
          xl: 123,
        },
        borderRadius: '10px',
      }}
      onClick={async () => {
        await dispatch(addNewThread(text, selectedModel));
      }}
    >
      <CardContent
        sx={{
          padding: "25px 15px 25px 15px"
        }}
      >
        <Typography variant="body2" component="p"
          sx={{
            fontSize: {
              xs: '0.875rem',
              sm: '0.875rem',
              md: '0.875rem',
              lg: '1rem',
              xl: '1rem'
            },
            fontWeight: '400',
            letterSpacing: '0.4px',
            textAlign: 'center'
          }}
        >
          "{text}"
        </Typography>
      </CardContent>
    </Card>
  );
}
