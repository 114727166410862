/*eslint-disable*/
import { FC, useEffect, useState } from "react";
import ChatMessageLoaderTypingMarkdown from "./ChatMessageLoaderTypingMarkdown";

const ChatMessageLoader: FC = () => {
  const [messages, setMessages] = useState([
    `Processing your request...`,
    `Just a moment, we're finding the best answer for you...`,
    `Hold tight, we're searching our knowledge base for the information you need...`,
    `Hmmm, it seems it is taking more time than usual...`,
    `Hang in there, we're working in the background...`,
    `Thanks for your patience while we assist you!`
  ]);

  const [ currentMessageIndex, setCurrentMessageIndex ] = useState(0);

  useEffect(() => {
    const messageTimer = setInterval( ( ) => {
      setCurrentMessageIndex( ( prevIndex ) => {
        if (prevIndex < messages.length - 1) {
          return prevIndex + 1;
        } else {
          return 0;
        }
      } );
    }, 8000 );

    return () => clearInterval( messageTimer );
  }, [ messages ] );

  return (
    <ChatMessageLoaderTypingMarkdown
      threadId={""}
      msgId={""}
      content={messages[currentMessageIndex]}
      key={messages[currentMessageIndex]}
      speed={20}
      showTyping={true}
      onTypingComplete={() => null}
    />
  );
}

export default ChatMessageLoader;