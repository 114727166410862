/*eslint-disable*/
import { useState, useRef, useEffect } from 'react';
import type { FC } from 'react';
import toast from 'react-hot-toast';
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
  Theme,
  Typography,
  FormControl,
  Select
} from '@material-ui/core';
import ArrowDropDownOutlined from '@material-ui/icons/ArrowDropDownOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { useDispatch, useSelector } from 'src/store';
import { addModel, resetActiveThread } from 'src/slices/chat';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const GETIR_40B_ID = 'RTA40B';
// export const RTA_7B_ID = 'RTA7B';
// export const OPENAI_ID = 1;

const optionsMapping = [
  { id: GETIR_40B_ID, name: 'RTA 40b' },
  // { id: RTA_7B_ID, name: 'RTA 7b' }
  // { id: OPENAI_ID, name: 'Open AI' },
];

const buttonStyles = {
  backgroundColor: colors.chatSideBar,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.chatSideBarHover,
  },
  '&:disabled': {
    backgroundColor: colors.chatNewDisableBtn,
    cursor: 'not-allowed',
    color: colors.chatNewDisableText
  },
};

const mobileButtonStyles = {
  backgroundColor: colors.white,
  color: colors.mainColor,
  '&:hover': {
    backgroundColor: colors.mainColor,
  },
  '&:disabled': {
    backgroundColor: colors.chatNewDisableBtn,
    cursor: 'not-allowed',
    color: colors.chatNewDisableText
  },
};

const SelectModel = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));

  const anchorRef = useRef<HTMLDivElement>(null);
  const { threads, activeThreadId, selectedModel } = useSelector(
    (state) => state.chat
  );
  const [disabledList, setDisabledList] = useState([]);
  const [dropdownDisabled, setDropDownDisabled] = useState(false);
  const thread = threads?.byId[activeThreadId];
  let msgLoading = false;
  if (!isEmpty(thread)) {
    msgLoading = thread.msgLoading;
  }

  const findIndexById = (id) => {
    return optionsMapping.findIndex((option) => option.id === id);
  };


  const handleMenuItemClick = (index: number, option: any) => {
    // const matchingOption = optionsMapping.find(
    //   (optionMapping) => optionMapping.name === option
    // );
    const id = option.id;
    dispatch(addModel(id));
    setSelectedIndex(index);
    setOpen(false);
  };
  const handleNewChatClick = async () => {
    try {
      await dispatch(resetActiveThread());
      // Navigate to '/home' on success
      navigate('/home');
    } catch (error) {
      // Handle error, you can log it or show a message to the user
      console.error('An error occurred:', error);
    }
  };
  return (
    <>
      <Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
          }}
        >
          <Button
            color="primary"
            className={`btn-newChat ${msgLoading || thread.msgTyping ? 'disabled' : ''}`}
            variant="outlined"
            onClick={handleNewChatClick}
            disabled={msgLoading || thread.msgTyping}
          >
            {t("newChat")}
          </Button>
        </Box>
      </Box>
      {/* <Box
        ref={anchorRef}
      >

        <Box
          sx={{
            display: 'flex',
            gap: '.25rem'
          }}
        >
          <Box className='dropdown-section'>
            <FormControl>
              <Select className='chat-select' disabled value={"40b"} color='primary'>
                <MenuItem value="40b">GETIR 40b</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className='dropdown-section'>
            <FormControl>
              <Select className='chat-select' disabled value={"1"}>
                <MenuItem value="1">Hugging Face (sentence-transformers)</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box className='dropdown-section'>
            <FormControl>
              <Select className='chat-select' disabled value={"TAMM"}>
                <MenuItem value="TAMM">TAMM</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

      </Box> */}
    </>
  );
};

export default SelectModel;
