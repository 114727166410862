/*eslint-disable*/
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { IconButton, Box, CircularProgress, Divider, Button, Theme, Tooltip, Typography, useMediaQuery, Link } from '@material-ui/core';
import {
  addAndGetMessage,
  addModel,
  addNewThread,
  //  getThread,
  getThreadByKey,
  resetActiveThread,
  resetChat,
  resetMessageThread,
  updateThreadNoLongerExist,
} from '../../../slices/chat';
import store, { useDispatch, useSelector } from '../../../store';
import type { RootState } from '../../../store';
import ChatMessageAdd from './ChatMessageAdd';
import ChatMessages from './ChatMessages';
import ChatThreadComposer from './ChatThreadComposer';
import { isEmpty } from 'lodash';
import { ChatMessageWidth, ToolBarHeight } from 'src/constants';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ChatCloseableButton from './ChatCloseableButton';
import colors from 'src/theme/colors';
import SelectModel from '../SelectModel';
import i18n from 'src/i18n';
import toast from 'react-hot-toast';
import { ArrowDownward } from '@material-ui/icons';

const threadSelector = (state: RootState): any => {
  const { threads, activeThreadId } = state.chat;
  const thread = threads.byId[activeThreadId];

  if (thread) {
    return thread;
  }

  return null;

  // return {
  //   id: null,
  //   message: [],
  //   unreadMessages: 0,
  //   msgLoading: false,
  //   loading: false,
  // };
};

const ChatThread: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { threadKey } = useParams();
  const { t } = useTranslation();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(760));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(760));
  const xlUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(1536));
  const {
    activeThreadId,
    threadNoLongerExist,
    loadingThreads,
    selectedModel,
    message,
    showSideBar
  } = useSelector((state) => state.chat);
  const thread = useSelector((state) => threadSelector(state));
  const isCallInProgress = useSelector((state: RootState) => state.chat.isCallInProgress);
  // const isCallInProgress = false;
  const { msgLoading = false, loading = false } = thread || {};

  const isTypingInProgress = React.useMemo(() => {
    return thread?.message?.some(message => !message?.showIcons && isEmpty(message?.senderId));
  }, [thread]);
  const parentRef = useRef<any>(null);
  const getActiveThreadId = () => store.getState().chat.activeThreadId;
  const [enableAutoScrollWhileTyping, setEnableAutoScrollWhileTyping] = useState<boolean>(true);
  const [showBottomNavigation, setshowBottomNavigation] = useState<boolean>(false);
  const onScroll = () => {
    if (parentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = parentRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight - (xlUp ? 150 : 90); // 100/50 should be adjusted with screen resolution
      if (isNearBottom) {
        setEnableAutoScrollWhileTyping(true);
      }
      else {
        setEnableAutoScrollWhileTyping(false);
        setshowBottomNavigation(true);
      }
      if (scrollHeight - scrollTop <= (clientHeight + 1)) {
        setshowBottomNavigation(false);
      }
    }
  };

  useEffect(() => {
    const listInnerElement = parentRef?.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement?.removeEventListener("scroll", onScroll);
      };
    }
  }, []);
  //Fix for FL-1135
  useEffect(() => {
    if (!activeThreadId) {
      if (!loadingThreads)
        return;
    } else {
      dispatch(resetChat());
    }
  }, [threadKey, loadingThreads]);

  // const currentState = store.getState();
  // // Access the state values
  // const activeThreadId = currentState.chat.activeThreadId;
  // const selectedThread = threadSelector(currentState);
  //Not Required
  // const getDetails = async (): Promise<void> => {
  //   try {
  //     // if (activeThreadId !== threadKey) { junaid
  //     await dispatch(getThreadByKey(threadKey));// this will set activeThreadId if thread found
  //     // Get the current state from the store
  //     const currentState = store.getState();
  //     // Access the state values
  //     const activeThreadId = currentState.chat.activeThreadId;
  //     const selectedThread = threadSelector(currentState);
  //     if (isEmpty(selectedThread)) {
  //       throw new Error();
  //     }

  //     // if (isEmpty(selectedThread?.message) && !selectedThread.loading) {
  //     //   await dispatch(getThread(threadKey));
  //     // }

  //     if (activeThreadId) {
  //       // pass 
  //     } else {
  //       // check from server
  //       // await dispatch(getThread(Number(threadKey)));
  //       const currentActiveThreadId = getActiveThreadId();
  //       if (!currentActiveThreadId)
  //         navigate('/dashboard/chat');
  //     }

  //     // if thread found then continue with that thread otherwise hit api and set stuff

  //     if (!isEmpty(message)) {
  //       await dispatch(resetMessageThread());
  //       // await dispatch(addModel(selectedModel));
  //       await dispatch(addAndGetMessage(message));
  //     }
  //     // }
  //   } catch (err) {
  //     await dispatch(resetActiveThread());
  //     navigate('/dashboard/chat');
  //     // If thread key is not a valid key (thread id or username)
  //     // the server throws an error, this means that the user tried a shady route
  //     // and we redirect him on the compose route
  //     console.error(err);
  //     // TODO:
  //     // navigate('/dashboard/chat/new');
  //   }
  // };
  // // Not required
  //   useEffect(() => {
  //     if (threadKey) {
  //       if (!loadingThreads)
  //        // getDetails();
  //         setshowBottomNavigation(false);
  //     } else {
  //       dispatch(resetActiveThread());
  //     }
  //   }, [threadKey, loadingThreads]);



  // In our case there two possible routes
  // one that contains chat/new and one with a chat/:threadKey
  // if threadKey does not exist, it means that the chat is in compose mode
  const mode = (activeThreadId || isCallInProgress) ? 'DETAIL' : 'COMPOSE';

  const handleSendMessage = async (message): Promise<void> => {
    try {
      if (!activeThreadId) {
        // create new thread and block UI
        await dispatch(addNewThread(message, selectedModel));
      } else {
        //if thread exists then manage here
        await dispatch(addAndGetMessage(message));
      }
    } catch (err) {
      console.error(err);
    }
  };
  // const checkedPlugins = pluginsArray.filter((plugin) => plugin.checked);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 80px)',
        paddingTop: !lgUp ? ToolBarHeight : '0px',
        ...(smDown && { width: '100%', margin: 'unset' }),
      }}
    >
      <Box
        sx={{
          // backgroundColor: !smDown ? 'background.default' : 'background.default',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'auto',
          height: '100%',
          width: '100%',
          ...(lgUp && { borderRadius: '1.875rem', border: '1px solid #fff' }),

        }}
      >

        {/* {!smDown && !showSideBar && (
          <Box sx={{ position: 'fixed', top: 8, left: 0, zIndex: 100 }}>
            <ChatCloseableButton toggleState={showSideBar ? false : true} />
          </Box>
        )} */}
        {mode === 'COMPOSE' && (
          <ChatThreadComposer />
        )}
        {loading ?
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <CircularProgress
              size={35}
              style={{ color: colors.mainColor }}
            />
          </Box>
          :
          <>
            {!smDown && thread?.message?.length > 0 &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                }}
              >
                <Box
                  sx={{
                    py: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    pr: 2,
                    width: '95%',
                    backgroundColor: 'theme.palette.background.default',
                    maxWidth: ChatMessageWidth,
                  }}
                >
                  <SelectModel />
                </Box>
              </Box>
            }
            <Box
              ref={parentRef}
              sx={{
                flexGrow: 1,
                overflow: 'auto',
                height: thread?.message?.length ? '100%' : '0%',
              }}
            >
              {!isEmpty(thread) && (
                <ChatMessages
                  messages={thread.message}
                  threadKey={thread.external_chat_id}
                  thread={thread}
                  enableAutoScrollWhileTyping={enableAutoScrollWhileTyping}
                  showBottomNavigation={showBottomNavigation}
                  parentRef={parentRef} />
              )}
            </Box></>
        }
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            mt: 2
          }}
        >
          <Box
            sx={{
              maxWidth: ChatMessageWidth,
              width: '100%',
              px: 2,
            }}
          >
            <ChatMessageAdd disabled={msgLoading || isCallInProgress || isTypingInProgress} onSend={handleSendMessage} />
          </Box>
          <Box
            sx={{
              maxWidth: ChatMessageWidth,
              width: '100%',
              pl: 4.5,
              alignItems: 'center',
              display: 'flex',
              justifyContent: !smDown ? 'flex-start' : 'center'
            }}
          >
            {!smDown && <Typography
              variant="caption"
              sx={{
                pb: 1.5,
                pt: 0.25,
                fontSize: '0.75rem',
                fontWeight: 400,
                color: colors.mainColorLight,
                letterSpacing: '0.32px'
              }}
            >
              {t('chat.policy.startText')}
              <RouterLink
                target="_blank"
                color="primary"
                style={{ textDecoration: 'none', marginLeft: '2px' }}
                to=""
                onClick={(ev: any) => ev.preventDefault()}
              // to="/policies/privacy-policy"
              >
                {t('chat.policy.privacyPolicy')}
              </RouterLink>
              {' and'}
              <RouterLink
                target="_blank"
                color="primary"
                style={{ textDecoration: 'none', marginLeft: '2px' }}
                // to="/policies/terms-of-use"
                to=""
                onClick={(ev: any) => ev.preventDefault()}
              >
                {t('chat.policy.termsOfService')}
              </RouterLink> {t('chat.policy.endText')}.
            </Typography>}
            {smDown &&
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  pb: 1.2,
                  pt: 1,
                }}
              >
                <Link
                  target="_blank"
                  color="cccprimary"
                  component={RouterLink}
                  sx={{ mt: 0, mr: '1.125rem' }}
                  // to="/policies/terms-of-use"
                  to=""
                  onClick={(ev: any) => ev.preventDefault()}
                  variant="body2"
                >
                  {t('loginForm.policy.termsOfUse')}
                </Link>
                <Typography variant="body2" sx={{ color: 'text.primary' }}>
                  {' | '}
                </Typography>
                <Link
                  target="_blank"
                  color="primary"
                  component={RouterLink}
                  sx={{ mt: 0, ml: '1.125rem' }}
                  // to="/policies/privacy-policy"
                  to=""
                  onClick={(ev: any) => ev.preventDefault()}
                  variant="body2"
                >
                  {t('loginForm.policy.privacyPolicy')}
                </Link>
              </Box>}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatThread;
