/*eslint-disable*/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import API from '../../api/apiInstance';

export const createUser = (payload: any) => API.post('/users', payload);

export const getUserByEmail = (email: string): any =>
  API.get(`/users?email=${encodeURIComponent(email?.trim())}`);

export const getUserById = (userId: string): any =>
  API.get(`/user/get-user-by-id?id=${userId}`);

export const listUser = (payload): any => API.get(`/users/list?${payload}`);

export const updateUser = (payload: any) => {
  const customerid = payload.customerid;
  delete payload.customerid;
  API.patch(`/users/${customerid}`, payload);
};

export const getUserRoles = (): any => API.get(`/users/role/list`);

export const deleteUser = (id: number) => API.delete(`/user/delete/${id}`);

export const uploadProfileImage = (payload: any) =>
  API.put('/user/upload-image', payload);

export const updateUserProfile = (payload: any) =>
  API.put('/user/profile', payload);

export const signinSync = (payload: any): any =>
  API.post('/auth/sync', payload);

export const upsertUser = (payload: any) => API.put('/user/profile', payload);
