/*eslint-disable*/
import { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import { CustomerListTable } from '../../../components/dashboard/customer';
import useMounted from '../../../../hooks/useMounted';
import ChevronRightIcon from '../../../../icons/ChevronRight';
import PlusIcon from '../../../../icons/Plus';
import useSettings from '../../../../hooks/useSettings';
import gtm from '../../../../lib/gtm';
import { listUser } from 'src/admin/api/user';
import APICallHistoryTable from 'src/admin/components/dashboard/api_call_history/APICallHistoryTable';
import {getAPIHistory} from 'src/api/chat';

const APICallHistory: FC = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [apiCallHistory, setAPICallHistory] = useState<any[]>([]);
  const [filteredAPICallHistory, setFilteredAPICallHistory] = useState<any[]>([]);
  const [loadingAPICallHistory, setLoadingAPICallHistory] = useState<boolean>(false);
  // const [addCustomer, setAddCustomer] = useState(false);
  const [tableParams, setTableParams] = useState<{
    page: number;
    limit: number;
    query: string;
  }>({
    page: 0,
    limit: 20,
    query: '',
  });

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getAPICallHistory = useCallback(
    async (pageNum = 0, limit = 10, query = '') => {
      try {
        setLoadingAPICallHistory(true);
        const res = await getAPIHistory();
        let idCounter = 1;
        const dataWithIds = res?.data.map((item) => ({
          ...item,
          id: idCounter++, // Assign a unique Id for each record
        }));

        console.log(dataWithIds);

        if (mounted.current) {
          setAPICallHistory(dataWithIds);
          setFilteredAPICallHistory(dataWithIds);
          setLoadingAPICallHistory(false);
        }
      } catch (err) {
        console.error(err);
        setLoadingAPICallHistory(false);
      }
    },
    [mounted]
  );

  useEffect(() => {
    getAPICallHistory();
  }, [getAPICallHistory]);

  useEffect(() => {
    setFilteredAPICallHistory(
      apiCallHistory.filter(
        (item) =>  (item.intent.join(',').indexOf(tableParams.query.toLowerCase()) > -1 || item.request_id.indexOf(tableParams.query.toLowerCase()) > -1)
      
        ));
  }, [tableParams]);

  return (
    <>
      <Helmet>
        <title>Dashboard: API Call History | Giter</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 1,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h4">
                API Call History
              </Typography>
              {/* <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  className="navLinkColorPrimary"
                  component={RouterLink}
                  to="#"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Link
                  className="navLinkColorPrimary"
                  component={RouterLink}
                  to="#"
                  variant="subtitle2"
                >
                  Management
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Users
                </Typography>
              </Breadcrumbs> */}
            </Grid>
            {/* <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  startIcon={<PlusIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => setAddCustomer(true)}
                >
                  Add Customer
                </Button>
              </Box>
            </Grid> */}
          </Grid>
          <Box sx={{ mt: 3 }}>
            <APICallHistoryTable
              apiCallHistory={filteredAPICallHistory}
              loading={loadingAPICallHistory}
              getAPICallHistory={getAPICallHistory}
              tableParams={tableParams}
              setTableParams={setTableParams}
            />
          </Box>
        </Container>
      </Box>

    </>
  );
};

export default APICallHistory;
