/*eslint-disable*/
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from '../store';
import { loginBypass } from 'src/api/auth';
import { setUserSession } from 'src/slices/auth';
import { getSavedUserSession, getSavedUserProfile } from 'src/store/localStorage';

interface AuthGuardProps {
  children: ReactNode;
}

const AdminAuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated = false } = useSelector((state) => state.auth);
  const { userProfile = {} } = useSelector((state) => state.user);
  const { role_id } = userProfile;
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const is_admin = role_id === 1 || role_id === 3;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const refreshTokenHandler = async () => {
    try {
      const session = getSavedUserSession() || {};
      const userProfile = getSavedUserProfile() || {};
      const tokens = await loginBypass(session.userSession.refreshToken, userProfile.email);

      const { AuthenticationResult: { AccessToken, IdToken, ExpiresIn, TokenType } } = tokens;

      const currentTime = Math.floor(Date.now() / 1000);
      const tokenExpiration = ExpiresIn;
      if (tokenExpiration - currentTime < 250) {
        const payload = {
          cognitoUserForIntermediateAction: null,
          isAuthenticated: true,
          isSocialLogin: false,
          userSession: {
            idToken: IdToken,
            accessToken: AccessToken,
            refreshToken: session.userSession.refreshToken
          }
        };

        await dispatch(setUserSession(payload))
      }
    } catch (error) {
      // Handle errors here, e.g., show a login screen
      navigate('/auth/logout');
      console.error('Error checking auth status:', error);
    }
  };

  // useEffect(() => {
  //   refreshTokenHandler();
  //   const timer = setInterval(refreshTokenHandler, 60000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  // TODO: 
  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/auth/login" />;
  }

  // TODO: 
  if (!is_admin) {
    return <Navigate to="/401" replace />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AdminAuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminAuthGuard;
