/* eslint-disable */
import { Suspense, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useRoutes, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {
  CssBaseline,
  Dialog,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core';
import RTL from './components/RTL';
// import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { SSO_ENABLED, gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import { createCustomAdminTheme } from 'src/admin/theme';
import SessionTimeout from './components/authentication/session-timeout';
import { getDepartmentsList, getSavedUserProfile, getSavedUserSession } from './store/localStorage';
import { useDispatch } from './store';
import { clearStore, setUserProfile } from './slices/user';
import { isEmpty } from 'lodash';
import i18n from './i18n';
import {
  setUserSession,
} from 'src/slices/auth';
import './App.css';
import { setDepartments } from './slices/metadata';

// eslint-disable-next-line
export let sessionTimeoutDispatcher = null;
let isSessionTimoutMessageShown = false;

export const appBguseStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }
}));

const App: FC = () => {
  const dispatch = useDispatch();

  // if (SSO_ENABLED) {
  //   const [searchParams] = useSearchParams();
  //   const userSession = searchParams.get('i');
  //   const userProfile = searchParams.get('p');
  //   if (userSession && userProfile) {
  //     const userSessionDecoded = atob(userSession);
  //     const userProfileDecoded = atob(userProfile);
  //     userSession && dispatch(setUserSession(JSON.parse(userSessionDecoded)));
  //     userProfile && dispatch(setUserProfile(JSON.parse(userProfileDecoded)));
  //     window.location.href = '/home';
  //     return;
  //   }
  // }

  const content = useRoutes(routes);
  const { settings } = useSettings();
  const [open, setOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  useScrollReset();

  const theme = createCustomTheme({
    language: settings.language,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });


  const adminTheme = createCustomAdminTheme({
    language: settings.language,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });


  const initializeUserData = () => {
    dispatch(setUserSession(getSavedUserSession()));
    dispatch(setUserProfile(getSavedUserProfile()));
    dispatch(setDepartments(getDepartmentsList()));
  };

  useEffect(() => {
    i18n.changeLanguage(settings.language);
    gtm.initialize(gtmConfig);
  }, []);

  initializeUserData();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function sessionTimout(message = '') {
    if (!isSessionTimoutMessageShown) {
      isSessionTimoutMessageShown = true;
      setModalMessage(message);
      handleOpen();
    }
  }
  sessionTimeoutDispatcher = sessionTimout;

  const useStyles = makeStyles(() => ({
    dialogPaper: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  }));

  const classes = useStyles();
  const navigate = useNavigate();

  if (isEmpty(content)) {
    return <SplashScreen />;
  }
  return (
    <Suspense fallback="Loading...">
      <ThemeProvider theme={useLocation().pathname === "/home" ? theme : adminTheme}>
        <RTL direction={settings.direction}>
          <CssBaseline />
          {/* <LanguageSelector /> */}
          <Toaster position="top-center" />
          {/* <SettingsDrawer /> */}
          {content}
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={null}
            open={isSessionTimoutMessageShown}
            classes={{ paper: classes.dialogPaper }}
          >
            <SessionTimeout
              message={modalMessage}
              onClick={async () => {
                isSessionTimoutMessageShown = false;
                await dispatch(clearStore());
                handleClose();
                navigate('/auth/logout');
              }}
            />
          </Dialog>
        </RTL>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
