/*eslint-disable*/
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
	Box,
	Container,
	Divider,
	Typography,
	experimentalStyled,
} from '@material-ui/core';
import gtm from '../../lib/gtm';
import GETIRLOGO from 'src/icons/getir-logo.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/landing.css';
import { ChatThread } from '../../components/dashboard/chat';
import { useSelector } from 'src/store';
import { GETIR_PORTAL_URL } from 'src/config';
import MainNav from 'src/components/navigation/MainNav';
import ChatLayout from './ChatLayout';
// import SelectModel from 'src/components/dashboard/SelectModel';

const PageWrapper = experimentalStyled(Box)(({ theme }) => ({
	backgroundColor: '#4c3398',
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	width: '100%'
}));

const BodyContainer = experimentalStyled(Container)``;

const HeaderWrapper = experimentalStyled(Box)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	'& .divider': {
		borderColor: theme.palette.common.white
	},
	'& .MuiIconButton-root': {
		height: 'fit-content',
		padding: 0
	}
}));

const ContentContainer = experimentalStyled(Container)(({ theme }) => ({
	backgroundColor: theme.palette.background.paper,
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
}));

const QuestionBox = experimentalStyled(Box)(({ theme }) => ({
	backgroundColor: '#F2F2F2',
	color: theme.palette.primary.dark,
	':hover': {
		backgroundColor: theme.palette.action.hover,
		color: theme.palette.primary.contrastText,
	}
}));

const LandingPage: FC = () => {
	const { t } = useTranslation();
	const { isAuthenticated = false } = useSelector((state) => state.auth);

	useEffect(() => {
		gtm.push({ event: 'page_view' });
	}, []);

	return (
		<>
			<Helmet>
				<title>{t("askGETIR")}</title>
			</Helmet>
			<ChatLayout>

				{/* <PageWrapper> */}

				{/* <BodyContainer className='body-container' maxWidth={false}>
						<HeaderWrapper className='header-wrapper'>
							<div className='logo-wrapper'>
								<RouterLink
									to=""
									onClick={(ev: any) => ev.preventDefault()}
								>
									<img alt="Attachment" src={GETIRLOGO} />
								</RouterLink>
								<Divider className='divider' orientation='vertical' variant='middle' />
								<Typography
									component="p"
									className='title'
									color="primary.contrastText"
								>
									{t("askGETIR")}
								</Typography>
							</div>
							{isAuthenticated && <MainNav />}
						</HeaderWrapper> */}
				<ContentContainer className='content-container' maxWidth={false}>
					<ChatThread />
				</ContentContainer>
				{/* </BodyContainer>
			</PageWrapper > */}
			</ChatLayout >
		</>
	);
};

export default LandingPage;
