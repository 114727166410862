/*eslint-disable*/
import Amplify, { Auth } from 'aws-amplify';
import { APP_MAIN_URL, amplifyConfig } from '../config';
import { trimmedString } from '../utils/index';
import API from './apiInstance';
const { CognitoIdentityServiceProvider, AWS } = require('aws-sdk');

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: amplifyConfig.aws_cognito_region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: amplifyConfig.aws_user_pools_id,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',

    oauth: {
      domain: amplifyConfig.aws_auth_domain,
      scope: ['phone', 'email', 'profile', 'openid'],
      redirectSignIn: `${APP_MAIN_URL}/auth/social-verification/`,
      redirectSignOut: `${APP_MAIN_URL}/auth/logout`,
      responseType: 'code',
    },
  },
});

export const initiateAuth = (ClientId, REFRESH_TOKEN, DEVICE_KEY) =>
  new Promise((resolve, reject) => {
    const CISP = new CognitoIdentityServiceProvider({ region: amplifyConfig.aws_cognito_region });

    CISP.initiateAuth(
      {
        ClientId, // Cognito App Client Id
        AuthFlow: 'REFRESH_TOKEN_AUTH',
        AuthParameters: {
          REFRESH_TOKEN,
          DEVICE_KEY
        }
      },
      (err, data) => {
        if (err) {
          return reject(err);
        }

        resolve(data);
      }
    );
  });

export const loginBypass = async (refreshToken: string, deviceKey: string): Promise<any> => {
  const tokens = await initiateAuth(amplifyConfig.aws_user_pools_web_client_id, refreshToken, deviceKey);
  return tokens;
};

export const login = (email: string, password: string): Promise<void> => Auth.signIn(trimmedString(email), password);

export const loginWithCreds = (email: string, password: string): Promise<any> => API.post(`/users/login`, { email, password }).then(res => res.data);

export const passwordRecovery = async (email: string): Promise<void> => {
  await Auth.forgotPassword(trimmedString(email));
};

export const passwordReset = async (
  username: string,
  code: string,
  newPassword: string
): Promise<void> => {
  await Auth.forgotPasswordSubmit(username, code, newPassword);
};

export const verifyCode = async (username: string, code: string): Promise<void> => {
  await Auth.confirmSignUp(username, code);
};

export const resendCode = async (username: string): Promise<void> => {
  await Auth.resendSignUp(username);
};

export const changePassword = async (oldPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, oldPassword, newPassword);
};

export const logout = (): Promise<void> => Auth.signOut();

export const getLoggedInUser = () => Auth.currentAuthenticatedUser();
